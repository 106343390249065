.text_color_lighter {
    color: #0777A9;
    opacity: 0.6;
}

.Hotel_listing .MuiBox-root {
    border-color: #0777A9;
    padding: 0px;
}

.Hotel_listing .MuiButtonBase-root {
    color: #7C7D83;
    text-transform: none;
    font-family: Lato, sans-serif;
}

.Hotel_listing .Mui-selected {
    color: #0777A9 !important;
    background-color: rgba(7, 119, 169, 0.15);
}

.Hotel_listing .MuiTabs-indicator {
    height: 0px !important;
}

.cursor_pointer {
    cursor: pointer;
}

.save_btn {
    width: 80px;
}

.room-tabs {
    min-width: 50% !important;
}

.w-fit {
    width: fit-content;
}

.count_label {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

/* Rates And Inventory CSS start */
.show.dropdown-toggle::after {
    rotate: 180deg;
    transition: 0.5s;
}

.dropdown-toggle::after {
    transition: 0.5s;
}

.bulk_dropdown_item {
    height: 40px;
    padding: 0px 10px;

    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #5C5D65;
    align-content: center;
    /* cursor: pointer; */
}

.bulk_dropdown_item:hover {
    color: #FFFFFF;
    background-color: #00A4BD;
}

/* Calender customized design start*/
.bulkCalendarBtn {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 8px;
    transition: right 0.2s;
}

.disableCalBtn {
    cursor: default !important;
}

.bulk_update_modal .react-datepicker-wrapper {
    position: absolute;
    bottom: 0px;
    height: 0px;
}

.bulk_update_modal .lengthfull .react-datepicker-wrapper {
    width: 50%;
}

.rates .react-datepicker-wrapper {
    position: absolute;
    bottom: 0px;
    height: 0px;
}

.rates .react-datepicker__triangle {
    left: -50% !important;
}

.rates .react-datepicker-popper {
    z-index: 3;
}

/* Calender customized design end */

/* Bulk Modals start*/
.bulk_update_modal_header {
    padding: 15px 12px;
}

.bulk_update_modal_title {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
}

.bulk_update_modal .MuiInputLabel-root {
    z-index: 0 !important;
}

.bulk_apply_btn {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.bulk_apply_btn:hover {
    color: #FFFFFF;
}

/* Bulk Modals end*/

/* Table design start */
.table_hotel_individual_rates_inventory {
    overflow-x: auto;
    width: 100%;
}

.hotel_individual_rates_inventory {
    width: max-content;
    background-color: #0777a926;
    border-bottom: 1px solid #DEDFE0;
    /* padding-right: 23px; */
}

.rates-inventory-table-calender {
    width: 100%;
    text-align: center;
}

.rates-inventory-table-calender thead tr th {
    padding: 10px !important;
}

.rates_inventory_header_title {
    min-width: 409px;
}

.rates_inventory_header_title h6 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #454750;
    text-align: left;
    padding-left: 0.5rem;
}

.slideButton {
    font-size: 0.75rem !important;
    border: none;
    background: #00000000;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.slideButton img {
    width: 0.75rem;
    height: 0.4625rem;
    font-size: 0.75rem !important;
}

.slidePrev {
    left: -60px;
}

.slidePrev img {
    transform: rotate(90deg);
}

.slideNext {
    right: 0px;
}

.slideNext img {
    transform: rotate(-90deg);
}

.date-and-day {
    width: 211px;
    text-align: left;
    padding: 0px 10px;
}

.date-and-day:nth-last-child(1) {
    padding-right: 20px;
}

.date-and-day .header_day {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

}

.date-and-day .header_date {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}

.inventory-room-name {
    color: #454750 !important;
    font-weight: 700;
    font-size: 1rem;
    line-height: 24px;
    padding: 12px 10px;

}

.room_count {
    position: absolute;
    left: 15rem;
}

.inventory-room-name-data {
    color: #454750 !important;
    font-weight: 500;
    box-shadow: none;
    font-size: 1rem;
    width: 100%;
    line-height: 24px;
    padding: 12px 10px;
}

.room_type_inner_rows {
    position: relative;
}

.edit_option {
    position: absolute;
    top: 5px;
    z-index: 3;
    right: 1rem;
}

.etm_room_count {
    position: absolute;
    left: 15rem;
}

tbody,
td,
tfoot,
th,
thead,
tr {
    padding-bottom: 10px !important;
}

.rates-inventory-table {
    width: 100%;
    text-align: center;
}

.rates-inventory-table thead,
.rates-inventory-table thead tr,
.rates-inventory-table thead tr th {
    padding: 0px !important;
    height: 0px;
}

.rates-inventory-table tbody tr {
    border-left: none;
}

.rates-inventory-table tbody tr td {
    border-right: 1px solid #DEDFE0;
    border-top: 1px solid #DEDFE0;
    padding: 10px !important;
}

.rates-inventory-table tbody tr td:nth-child(1) {
    border-right: 1px solid #DEDFE0 !important;
    border-top: 1px solid #DEDFE0 !important;
}

.rates-inventory-table tbody tr td:nth-last-child(1) {
    border-right: none;
}

.rates-inventory-table tbody tr td label {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: #454750;
}

.rates-inventory-table .colspan_2 .col_1 {
    width: 160px;
    align-content: start;
}

.rates-inventory-table .colspan_2 .col_2 {
    width: 240px;
}

.common-input {
    width: 210px;
    text-align: left;
    border: none;
    padding: 0px 10px;
    outline: 0px;
}

.hotel_rate_inventory {
    width: 1564px;
}

.hotel_rate_inventory .accordion-item {
    border: none;
}

.hotel_rate_inventory .accordion-item:nth-last-child(1) {
    border: none;
}

.inventory_data .accordion-item {
    border: none;
    border-bottom: 1px solid #DEDFE0 !important;
}

.hotel_rate_inventory .accordion-button.inventory-room-name {
    border: none;
    background-color: #E9E9EA;
    border-bottom: 1px solid #DEDFE0;
}

.hotel_rate_inventory .inventory-room-name-data.accordion-button.collapsed {
    border: none;
}

.hotel_rate_inventory .inventory-room-name-data.accordion-button {
    border: none;
    background-color: #F4F5F5;
}

.hotel_rate_inventory .accordion-button::after {
    position: absolute;
    left: 23rem;
    background-image: url('../../../../assets/img/expand_more.png') !important;
    background-size: 0.75rem !important;
    width: 0.75rem;
    height: 0.4625rem;
}

/* Table design end */

/* Rates And Inventory CSS end */

/* ............................................ */

.bulkCalendarBtn {
    position: absolute;
    top: 16px;
    right: 8px;
    transition: right 0.2s;
}

.bulk_update_modal .react-datepicker-wrapper {
    position: absolute;
    bottom: 0px;
    height: 0px;
}

.bulk_update_modal .lengthfull .react-datepicker-wrapper {
    width: 50%;
}

/* ................................photoes.................................. */

.photo-upload-card {
    border-radius: 10px;
}

.photo-images-card {
    border-radius: 10px;
}

.h-241px {
    height: 241px;
}

.images-accordian {
    --Paper-shadow: none
}

/* room design start */
.MuiDateRangeCalendar-root div::first-line {
    display: none !important;
    font-size: 0.1px !important;
}

.range .MuiTypography-root {
    display: none;
}

.cot_m .MuiFormHelperText-sizeMedium {
    position: absolute !important;
    bottom: -20px;
    margin: 0px 0px;
}

.cot_m .MuiFilledInput-root {
    position: relative !important;
}

/* room design end */

/* Image & Video design css start */

.image_video_list {
    box-shadow: none !important;
    border-bottom: 1px solid #DEDFE0;
    border-radius: 0px !important;
}

.image_video_list .MuiTypography-root {
    /* font-size: 18px; */
    font-weight: 500;
    line-height: 27px;
    color: #5C5D65;
}

.image_video_hover {
    background-color: #00000078;
    top: 44%;
    bottom: 44%;
    left: 40%;
    right: 40%;
    height: 14%;
    width: 20%;
}

.image_video_hover i {
    color: #fff;
}

.image_video_hover i.disabled {
    color: gray;
}

.object-fit-fill {
    object-fit: fill;
}

/* Image & Video design css start */


/* policies started */

.plicies-tabs-btn {
    width: fit-content;
    padding: 8px;
    border-radius: 10px;
    background-color: rgba(7, 119, 169, 0.15) !important;
    justify-content: space-around;
}

.plicies-tabs-in-btn {
    width: auto;
    padding: 8px 16px 8px 16px;
    border-radius: 8px;
}

.tab-btn-white {
    background-color: #FFFFFF;
}


.policies_border {
    border-bottom: 1px solid #DEDFE0
}

.cursor-pointer {
    cursor: pointer;
}

.popmes {
    z-index: 99;
    top: 0;
    width: 330px;
    position: absolute;
    padding: 2px 5px;
    border-radius: 0.4rem;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    animation: popmes 5s normal forwards ease-in-out;
}

.popmes:after {
    top: 10%;
    color: #fff;
    border-right: 8px solid;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    display: inline-block;
    content: '';
    position: absolute;
    left: -6px;
}

@keyframes popmes {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.bg_pill {
    background-color: rgba(7, 119, 169, 0.15);
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}