/*Jdieditor start*/
/* .container-fluid .d-flex {
  background: none !important;
} */

.jodit-wysiwyg_mode {
  min-height: 100% !important;
  min-width: 100% !important;
}

.jodit-workplace {
  z-index: 0 !important;
  min-height: 83px !important;
}

.jodit-wysiwyg {
  min-height: 121px !important;
}

.jodit-add-new-line_after {
  display: none !important;
}

.jodit-wysiwyg li {
  list-style: unset !important;
}

/*Jdieditor end*/

/* time picker start */
.react-time-input-picker-wrapper {
  /* display: block; */
  width: 100% !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.react-time-input-picker-wrapper {
  justify-content: start !important;
  margin: 0px 0px !important;
  padding: 0px 0px !important;
}

.inputWrapper__dropdown {
  z-index: 1;
  min-width: 120% !important;
  max-width: 120% !important;
}

.react-time-input-picker input {
  min-width: 5px !important;
  justify-content: start !important;
}

.inputWrapper {
  max-width: 40px !important;
}

.text-container {
  position: relative;
}

.calendarBtn {
  position: absolute;
  top: 5px;
  right: 10px;
  transition: right 0.2s;
}

.changecolor {
  background-color: #e9ecef !important;
}

.defo_border:hover {
  border: 1px solid #ced4da;
}

.ant-picker-focused {
  border: 1px solid #0777A9 !important;
  box-shadow: 0 0 0 0.2rem rgba(40, 142, 167, 0.25) !important;
}

.defo_border .ant-picker-suffix {
  color: #414141;
}

.defo_border input::placeholder {
  color: hsl(0, 0%, 50%) !important;
}

/* time picker end */

/* date picker start */
.text-container {
  position: relative;
}

.calendarBtn {
  position: absolute;
  top: 5px;
  right: 10px;
  transition: right 0.2s;
}

.react-datepicker__close-icon {
  right: 10% !important;
}

.react-datepicker__close-icon::after {
  background-color: #414141 !important;
}

.react-datepicker__month-dropdown-container--scroll {
  margin-right: 20px !important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow {
  top: 2px !important;
}

/* date picker end  */

/*Confirm Modal*/
.modelheader {
  border-bottom: none !important;
  padding: .3rem 1rem;
  background-color: #0777A9 !important;
}

.modelheader>.btn-close {
  margin: 0rem 0rem 0rem 0rem !important;
  padding: 0.2rem 0.2rem !important;
  opacity: 0.9 !important;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;

}

.modalbody {
  padding: 0rem .5rem;
}

.modalfooter {
  border-top: transparent;
  padding: .5rem .5rem;
}

.modelheader>.header_text {
  color: #fff;
  justify-content: center;
  display: flex;
}

/*end*/
/* Session Modal start*/
.session_headermodal {
  background-color: #0777A9 !important;
  padding: .3rem 1rem;
}

.session_headermodal>.session_title {
  color: white;
  text-align: center;
}

.session_headermodal>.closebtn_position {
  margin: 0rem 0rem 0rem 0rem !important;
  padding: 0.2rem 0.2rem !important;
  opacity: 0.9 !important;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}

/* Session Modal end*/

/* counter component */
.counter_btn_main {
  /* background-color: #35a5d77a !important; */
  background-color: rgba(7, 119, 169, 0.15) !important; 
  color:#ffffff
}

.counter_btn{
  width: 30px;
  height: 30px;
}

.counter_btn:disabled{
  background-color: rgba(48, 102, 128, 0.15) !important; 
}